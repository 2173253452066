<i18n locale="th" lang="yaml" >
chart.name : "จำนวน Drone แต่ละ Lot ของ รุ่นนี้"
chart.summary : "มี Drone ทั้งหมด {total} @:common.drone.unit"
</i18n>

<template>
	<ChartContainer
		ref="container"
		:loading="loading"
		:is-empty="total <= 0"
		:error-message="error"
		:summary="summary"
		v-bind="$attrs">
		<HorizontalBarChart ref="chart" />
		<span slot="title">
			{{$t('chart.name')}} <br/>
			<b>{{modelName}}</b>
		</span>
	</ChartContainer>
</template>

<script>
import axios from "axios"
import ChartContainer from "./general/ChartContainer.vue"
import HorizontalBarChart from "./general/HorizontalBarChart.vue"
import _randomColor from "randomcolor"
import ApiError from '@utils/errors/ApiError'
import {droneStatusColors} from "@utils/chartUtil"
export default {
	components : {
		ChartContainer , HorizontalBarChart
	} ,
	props : {
		modelName : {
			type : String,
			default : undefined
		} ,
		modelId : {
			type : Number,
			default : 0,
		} ,

	} ,
	data() {
		return {
			total : 0,
			loading : false,
			error : undefined,
		}
	} ,
	computed : {
		summary() {
			if(this.total <=0)
				return undefined;
			else
				return this.$t('chart.summary',{total : this.total})
		}
	} ,
	watch :{
		modelId() {
			this.fetchData()
		}
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		reset() {
			this.total = 0
			this.error = null
			this.loading = false
		} ,
		convertDataset(dataset,statusList) {
			if (!this.$notEmpty(dataset))
				return null

			const labels = [];
			const dataByStatus = {};

			Object.values(dataset).forEach((lotDataset) => {
				labels.push(lotDataset.lot_name)
				for(const status in lotDataset.by_status) {
					if (!(status in dataByStatus))
						dataByStatus[status] = []
					dataByStatus[status].push(lotDataset.by_status[status])
				}
			});

			const chartDatasets = [];
			const statusColors = droneStatusColors();

			statusList.forEach((status) => {
				if (!(status in dataByStatus))
					return;

				const color = (status in statusColors) ? statusColors[status] : _randomColor()

				chartDatasets.push({
					label : this.$tenum('drone.status',status) ,
					backgroundColor : color,
					maxBarThickness : 48,
					data : dataByStatus[status]
				})
			});

			return {
				labels ,
				datasets : chartDatasets
			}
		} ,
		fetchData() {
			this.reset()
			if (this.modelId <= 0) {
				this.$refs.chart.applyChartData()
				return;
			}

			this.loading = true
			this.error = undefined
			axios.get("/api/drone-models/"+this.modelId+"/stat-drones-per-lot").then((response) => {
				this.total = response.data.data.total;
				const chartDataset = this.convertDataset(response.data.data.dataset,response.data.data.status_list)
				const chartOptions = {
					scales : {
						xAxes : [
							{stacked : true,ticks : {min : 0,precision:0}}
						] ,
						yAxes : [
							{stacked : true}
						]
					}
				}
				this.$refs.chart.applyChartData(chartDataset,chartOptions)
			}).catch((error) => {
				this.error = ApiError.getDisplayErrorMessage(this,error)
			}).finally(() => {
				this.loading = false
			});
		}
	}
}
</script>
