<script>
import {HorizontalBar} from "vue-chartjs"
export default {
	extends : HorizontalBar ,
	props : {
		width : {
			type : Number,
			default : undefined
		} ,
		height : {
			type : Number,
			default : undefined,
		} ,
	},
	methods: {
		applyChartData(chartData=null,chartOptions={}) {
			if (chartData) {
				this.renderChart(chartData,{
					responsive : true,
					legend : {position : 'bottom'} ,
					animation: {
						animateScale: true,
						animateRotate: true
					} ,
					...chartOptions
				})
			}
		}
	}
}
</script>
