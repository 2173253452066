<i18n src="@i18n/drone/drone_lot.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
button.create_lot : "สร้าง Lot ใหม่"
model_lots.not_valid : "ยังไม่สามารถสร้าง Lot ได้"
model_lots.not_valid.description : "ควรกรอกข้อมูลอะไหล่ต่าง ๆ ให้เรียบร้อยก่อน จากนั้นปรับสถานะเป็นเริ่มนำไปใช้งาน เพื่อเพิ่มข้อมูล Lot ได้ (เพราะข้อมูลอะไหล่ ของ Model จะได้ตาม Lot ไปด้วย)"
</i18n>

<template>
	<div class="page-padding">
		<div v-if="showNotValid" class="lot-not-valid">
			{{$t('model_lots.not_valid')}}
			<div class="description">
				{{$t('model_lots.not_valid.description')}}
			</div>
		</div>
		<template  v-else>
			<a-button
					v-if="canCreateLot"
					class="btn-page-action"
					type="primary"
					icon="plus"
					@click="clickCreateLot">
					{{$t('button.create_lot')}}
			</a-button>

			<DronesPerModelBar
				v-if="showNumberOfDrones"
				bordered
				style="margin-bottom:32px;"
				class="center-block"
				:container-width="600"
				:model-id="model.id"
				:model-name="model.name"/>

			<a-table
				:loading="loading"
				bordered class="mytable"
				:row-key="record => record.id"
				:data-source="lots"
				:pagination="false"
				size="small">
				<a-table-column
					align="center"
					:width="40">
					<template slot-scope="text,record,index">
						{{index + 1}}.
					</template>
				</a-table-column>
				<a-table-column
					:title="$t('drone_lot.field.lot_name')" data-index="lot_name">
					<template slot-scope="text,record">
						<router-link :to="{ name: 'drone_lot/view',params: { id: record.id } }">
							{{text}}
						</router-link>
					</template>
				</a-table-column>
				<a-table-column
					v-if="showNumberOfDrones"
					:title="$t('drone_lot.field.num_drones')" data-index='num_drones'
					:width="75"
					align="center">
					<template slot-scope="text">
						{{text}}
					</template>
				</a-table-column>
				<a-table-column
					:title="$t('drone_lot.field.manufacture_date')" data-index='manufacture_date'
					:width="150"
					align="center">
					<template slot-scope="text">
						{{text | date}}
					</template>
				</a-table-column>

				<a-table-column
					:title="$t('drone_lot.field.remark')"
					data-index="remark"
					:width="50"
					align="center">
					<template slot-scope="remark">
						<MyTooltip v-if="$notEmpty(remark)"
							:destroy-tooltip-on-hide="true">
							<div slot="title" v-trim class="multiline">
								{{remark}}
							</div>
							<a-icon type="message" class="cursor-pointer"/>
						</MyTooltip>
					</template>
				</a-table-column>
			</a-table>
		</template>

	</div>
</template>

<script>
import DronesPerModelBar from "@components/chart/DronesPerModelBar.vue"
import MyTooltip from "@components/common/MyTooltip.vue"
import PageMixin from "@mixins/PageMixin.vue"
import axios from "axios"
import {Table} from "ant-design-vue"

export default {
	components : {
		MyTooltip, DronesPerModelBar,
		"a-table" : Table, "a-table-column" : Table.Column
	} ,
	mixins : [PageMixin] ,
	props : {
		model : {
			type: Object ,
			default : () => []
		} ,
		numLots : {
			type : Number,
			default : 0
		} ,
		numDrones : {
			type : Number,
			default : 0,
		}
	} ,
	data() {
		return {
			loading : false,
			lots : []
		}
	} ,
	computed : {
		canCreateLot() {
			return this.model.id && this.model.status === 'active' && this.$authorize('create','droneLot');
		} ,
		showNotValid() {
			return this.model.id && this.model.status === 'draft'
		} ,
		showNumberOfDrones() {
			return this.$authorize('listAll','drone')
		}
	} ,
	watch: {
		model : {
			handler() {
				this.fetchData()
			} ,
			deep: true
		}
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		clickCreateLot() {
			this.$router.push({
				name:'drone_model/lot_create',params:{id : this.model.id}
			})
		} ,
		fetchData() {
			if (!this.model || !this.model.id)
				return
			this.showPageLoading(true)
			this.loading = true
			axios.get("/api/drone-models/"+this.model.id+"/lots").then((response) => {
				this.lots = response.data.data.drone_lots;
			}).catch((error) => {
				this.fetchError(error);
			}).finally(()=>{
				this.hidePageLoading()
				this.loading = false
			})
		} ,
	}
}
</script>
<style lang="less" scoped>
.lot-not-valid {
	font-size : 2em;
	color : @error-color;
	font-family: @font-family-title;
	margin : 24px auto;
	padding : 16px;
	text-align: center;
	max-width : 500px;

	.description {
		font-size : 0.5em;
		color : @text-color;
		margin-top : 24px;
	}
}
</style>
